export const formatCurrency = (str: string | number) =>
	Number(str).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
// Number(str)
// 	.toFixed(2)
// 	.replace(/^\d{1,3}(?:\.\d{3})*,\d{2}$/g, '$&.')

export const getInstallments = (total: number) => {
	const quantInstallment: number = 12
	const result = []
	for (let i = 0; i < quantInstallment; i++) {
		const partial = Math.round((total * 100) / (i + 1)) / 100
		result.push({ label: `${i + 1}X ${formatCurrency(partial)}`, value: i + 1 })
	}
	return result
}

export const base100ToBase10 = (v: number) => {
	try {
		const value = `${v}`
		const int = value.substr(0, value.length - 2)
		const decimal = value.substr(value.length - 2)
		const base10 = Number(`${int}.${decimal}`)
		return base10
	} catch {
		return '-'
	}
}
