import { Col as C, Grid as G } from '@cuidardigital/commons/components/Grid/grid'
import styled, { css } from 'styled-components'
import { Button as B, ButtonLink as BL, Span as S } from '../superSetCommons'

interface IWrapper {
	bg?: string
}
export const Span = styled(S)`
	display: block;
`
export const Button = styled(B)``
export const Grid = styled(G)``
export const Col = styled(C)``
export const ComponentWrapper = styled(G)`
	background: ${({ bg }: IWrapper) => bg || '#ffffff'} 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 3px #00000026;
	border-radius: 8px;
	opacity: 1;
	padding: 22px;
	grid-row-gap: 0;

	.hidden {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 0.5s ease-in-out;
	}

	.show {
		max-height: 100vh;
		transition: max-height 1s ease-in-out;
		padding-top: 15px;
	}

	svg {
		transition: 0.5s;
		width: 20px;
		height: 20px;
	}

	@media (max-width: 480px) {
		border: 1px solid ${props => props.theme.colors.secundary};
		margin: 5px;
		display: none;
	}
`

export const ItemTitle = styled(S)`
	text-align: left;
	letter-spacing: 0px;
	color: ${props => props.theme.colors.font_dark};
	display: block;
`

export const ItemSubtitle = styled(S)`
	width: 200px;
	text-align: left;
	letter-spacing: 0px;
	color: #666666;
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	@media (max-width: 480px) {
		width: auto;
	}
`

export const ButtonCss = css`
	width: 30%;
	margin: 15px 8px 15px 0;

	@media (max-width: 480px) {
		margin: 5px 8px 0;
		height: 30px;
		overflow: hidden;
		padding: 5px;
		width: 33%;
	}
`

export const EditButton = styled(B)`
	${ButtonCss};
	margin-right: 8px;
	// opacity: ${({ disabled }: any) => (disabled ? '0.5' : '1')};
	background: ${props => props.theme.colors.secundary};
	color: ${props =>
		props.disabled ? props.theme.colors.font_disabled : props.theme.colors.font_secundary};
	border: 1px solid ${props =>
		props.disabled ? props.theme.colors.font_disabled : props.theme.colors.secundary_border};
	&:hover {
		background: ${props =>
			props.disabled ? props.theme.colors.secundary : props.theme.colors.secundary_hover};
	}
`

export const DisapproveButton = styled(B)`
	${ButtonCss};
	border: 1px solid ${props => props.theme.colors.red_alert_border};
	background: ${props => props.theme.colors.red_alert};
	&:hover {
		background: ${props => props.theme.colors.red_alert_hover};
	}
`

export const ResendButton = styled(BL)`
	${ButtonCss};
	width: auto;
	color: ${props => props.theme.colors.secundary};
	text-decoration: underline;
	font: 16px/23px CentraleSans-Bold;
	@media (max-width: 1340px) {
		font: 14px/23px CentraleSans-Bold;
	}
	@media (max-width: 768px) {
		font: 12px/23px CentraleSans-Bold;
	}
`

export const ComponentWrapperMobile = styled(ComponentWrapper)`
	display: none;
	@media (max-width: 480px) {
		display: grid;
	}
`
