import React from 'react'

export default function({ checked, ...props }) {
	const color = 'white'
	// if (typeof window !== 'undefined') {
	// 	if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	// }
	if (checked)
		return (
			<svg
				width='17'
				height='17'
				viewBox='0 0 17 17'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				{...props}
			>
				<path
					d='M15.5833 2.125H1.41663L7.08329 8.82583V13.4583L9.91663 14.875V8.82583L15.5833 2.125Z'
					stroke='#2760E3'
					stroke-width='2'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</svg>
		)
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 17 17'
			fill='none'
			{...props}
		>
			<path
				d='M15.5827 2.125H1.41602L7.08268 8.82583V13.4583L9.91602 14.875V8.82583L15.5827 2.125Z'
				stroke={color}
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}
