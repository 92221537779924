import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
	deleteSecretary,
	getSecretaries,
	postSecretaryCreation,
	reactivateSecretary,
	updateSecretaryByDoctor
} from '@cuidardigital/commons/services/secretary.services'

import ArrowIcon from '@cuidardigital/commons/assets/icons/arrowBack'
import FilterIcon from '@cuidardigital/commons/assets/icons/filter'
import { Loader } from '@cuidardigital/commons/components/Loader'
import IAssistant from '@cuidardigital/commons/types/IAssistant'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import { isoStringToDateString } from '@cuidardigital/commons/utils/date'
import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import TemplatePage from '../../templates/Page'
import { store } from '../../stores/app'
import { initUserSession } from '../../utils/session'
import FinancialResume from '../Cards/financialResume'
import CollapseFinancial from '../Collapse/FinancialCollapse'
import MessageModal from '../Modal'
import FinancialFilter from './filter'
import {
	Button,
	Col,
	ColAssistant,
	ColBreadCrumb,
	ComponentWrapper,
	Grid,
	Icon,
	LinkPage,
	NoContentWrapper,
	Span,
	SpanContainer,
	WrapperGrid,
	ColContainer,
	GridResume,
	ButtonCheckable
} from './styles'
import { FINANCIAL_REGISTER } from '../../routes'

const Financial = () => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)
	initUserSession(state, dispatch)
	const [loading, setLoading] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()

	const [assistantList, setAssistantList] = useState([])
	const [modalFilterOpen, setModalFilterOpen] = useState(false)
	// @ts-ignore
	const [showModalConfirm, setShowModalConfirm] = useState({
		open: false,
		message: '',
		onConfirm: null,
		assistant: { name: '', id: '' }
	})
	const [active, setActive] = useState({})

	const [values] = useState({
		'Consultas realizadas': '26',
		'Total das consultas': 'R$8.000,00',
		'Saldo liberado': 'R$5.200,00',
		'Saldo a liberar': 'R$1.600,00',
		Pendente: 'R$ 1.200,00'
	})

	const data = useStaticQuery(query)
	const {
		title,
		listTitle,
		button,
		paragraph,
		cards: cardsCms
	} = data.saudeDigital.metadata.financial

	const cards = useMemo(
		() =>
			cardsCms.map(card => ({
				...card,
				value: values[card.title]
			})),
		[cardsCms]
	)

	useEffect(() => {
		fetchAssistant()
	}, [state.session.cpf])

	useEffect(() => {
		const timer = window.setInterval(() => {
			if (!showModalConfirm.open && !modalFilterOpen) fetchAssistant()
		}, 30000)
		return () => {
			window.clearInterval(timer)
		}
	}, [state, showModalConfirm.open, modalFilterOpen])

	const handleCollapse = useCallback(
		item => () => setActive(prev => (prev.id === item.id ? {} : item)),
		[]
	)

	const handleConfirmDelete = useCallback(
		(assistant: any) => () => {
			setShowModalConfirm({
				open: true,
				message: 'DESATIVAR',
				// @ts-ignore
				onConfirm: () => handleDelete(assistant),
				assistant
			})
		},
		[]
	)

	const handleDelete = (assistant: IAssistant) => {
		setLoading(true)
		deleteSecretary({ secretaryId: assistant.id })
			.then((response: any) => {
				setLoading(false)
				if (response.status < 207) {
					setShowModalConfirm({
						open: false,
						message: '',
						onConfirm: null,
						assistant: { name: '', id: '' }
					})
					setMessageToShow({
						title: 'Pronto',
						message: 'Desativado com sucesso',
						buttonText: 'Voltar'
					})
					setShowMessage(true)
					fetchAssistant()
				} else {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: response.date.message,
						buttonText: 'Ok, entendi'
					})
					setShowMessage(true)
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpa',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
			})
	}
	const handleReactivate = (assistant: IAssistant) => {
		setLoading(true)
		reactivateSecretary({ secretaryId: assistant.id })
			.then((response: any) => {
				setLoading(false)
				if (response.status < 207) {
					setShowModalConfirm({
						open: false,
						message: '',
						onConfirm: null,
						assistant: { name: '', id: '' }
					})
					setMessageToShow({
						title: 'Pronto',
						message: 'Reativado com sucesso',
						buttonText: 'Ok, entendi'
					})
					fetchAssistant()
				} else {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: response.data.message,
						buttonText: 'Ok, entendi'
					})
				}
				setShowMessage(true)
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpa',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
			})
	}

	const handleCloseModalConfirm = useCallback(() => {
		setShowModalConfirm({
			open: false,
			message: '',
			onConfirm: null,
			assistant: { name: '', id: '' }
		})
	}, [])

	const handleOpenFilter = useCallback(() => {
		setModalFilterOpen(prev => !prev)
	}, [])

	const fetchAssistant = () => {
		setLoading(true)
		getSecretaries({ doctorCpf: state.session.cpf })
			.then((response: any) => {
				setLoading(false)
				if (response) {
					if (response.status === 200) {
						const assistants = (response.data || []).map((item: any, index) => ({
							id: item.id,
							name: item.name,
							status: index % 2 === 0 ? item.status : 'PENDENT',
							birthDay: item.birthDay,
							birthDayFormated: isoStringToDateString(item.birthDay || new Date().toISOString()),
							value: (item.phone || '55478.90').replace(/\D/g, '').substr(0, 6)
						}))
						setAssistantList(assistants)
					} else if (response.status === 204 && assistantList.length) {
						setAssistantList([])
					}
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpas',
					message: JSON.stringify(e)
				})
				setShowMessage(true)
			})
	}

	const handleSubmitAssistant = useCallback(
		assistant => {
			setLoading(true)
			if (!assistant?.id) {
				postSecretaryCreation({ ...assistant })
					.then(response => {
						if (response.status <= 204) {
							setMessageToShow({
								title: 'Secretário(a) cadastrado(a)',
								message: `Agora, ${assistant.name} receberá por email os passos para confirmar o cadastro.`,
								buttonText: 'Ok, entendi'
							})
							setShowModal({ open: false, assistant: null })
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: response.data.message
							})
						}
						fetchAssistant()
					})
					.catch(e => {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: JSON.stringify(e)
						})
					})
					.finally(() => {
						setShowMessage(true)
						setLoading(false)
					})
			} else {
				updateSecretaryByDoctor({ assistant, doctorCpf: state.session.cpf })
					.then(response => {
						if (response.status <= 204) {
							setMessageToShow({
								title: 'Secretário(a) atualizado(a)',
								message: `Agora, ${assistant.name} receberá por email os passos para confirmar o cadastro.`,
								buttonText: 'Ok, entendi'
							})
							setShowModal({ open: false, assistant: null })
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: response.data.message
							})
						}
						fetchAssistant()
					})
					.catch(e => {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: JSON.stringify(e)
						})
					})
					.finally(() => {
						setShowMessage(true)
						setLoading(false)
					})
			}
		},
		[state.session]
	)

	// TODO checar se possui endereço e redirecionar para tela de cadastro
	// if (
	// 	state.session.accessToken &&
	// 	(!state.doctorCurrent.isSeller || !state.doctorCurrent.waitingFinancialRegister)
	// ) {
	// 	navigate(FINANCIAL_REGISTER)
	// 	return ''
	// }

	return (
		<ComponentWrapper>
			{loading && <Loader />}
			{showMessage && (
				<MessageModal
					onClose={setShowMessage}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
			{state.session.accessToken && (
				<TemplatePage>
					<WrapperGrid>
						<Col className='right' size='12'>
							<NoContentWrapper>
								<ColBreadCrumb size='6' tablet='12' flex>
									<Link id='butto-arrow-back' to='/'>
										<ArrowIcon alt='Seta' />
									</Link>
									<SpanContainer>
										<Span bold fontSize='32px/40px'>
											{title}
										</Span>
									</SpanContainer>
									<Span fontSize='12px/20px' sm='0'>
										<LinkPage to='/'>Página inicial</LinkPage>
									</Span>
									<Span fontSize='12px/20px' sm='0' padding='0 5px'>
										/
									</Span>
									<Span fontSize='12px/20px' sm='0'>
										{title}
									</Span>
								</ColBreadCrumb>
								<Col size='6' tablet='12' flex justifyContent='flex-end'>
									<ButtonCheckable
										filter
										checked={modalFilterOpen}
										id='button-register-secretary'
										width='auto'
										onClick={handleOpenFilter}
										icon
										zIndex={modalFilterOpen && '100'}
										position='fixed'
									>
										<FilterIcon
											style={{ padding: '3px', borderRadius: '35% 35% 50% 50%' }}
											width='25px'
											height='25px'
											checked={modalFilterOpen}
										/>
										<Span
											notMobile
											bold
											fontSize='16px/24px'
											color='white'
											padding='0px 0px 0px 10px'
										>
											{button}
										</Span>
									</ButtonCheckable>
								</Col>
							</NoContentWrapper>
						</Col>
						<ColContainer size='12' overflow='hidden'>
							<GridResume>
								{cards.map((card, index) => (
									<Col
										size='2'
										fullHd='2'
										hd='3'
										tablet='3'
										mobile='1'
										key={`financial-card-${index}`}
									>
										<FinancialResume
											htmlId={`card-financial-resume-${index}`}
											icon={card.icon}
											bg={card.bg}
											title={card.title}
											text={card.value}
											info={card.paragraph}
										/>
									</Col>
								))}
							</GridResume>
						</ColContainer>
						<Col size='12' padding='20px 0 0'>
							<Span fontSize='24px/24px' bold>
								{listTitle}
							</Span>
						</Col>

						<Col size='12'>
							<Grid>
								{assistantList.length > 0 ? (
									assistantList.map((item: IAssistant, index: number) => (
										<Col key={`key-collapse-group-${index}`} size='12'>
											<CollapseFinancial
												htmlId={`col-financial-item-${index}`}
												active={item.id === active.id}
												onCancel={handleConfirmDelete(item)}
												onResend={handleSubmitAssistant}
												onCollapse={handleCollapse(item)}
												{...item}
											/>
										</Col>
									))
								) : (
									// <Pagination setPage={setPage} totalPages={totalPages} page={page}></Pagination>
									<Col size='12'>
										<Span fontSize='14px/20px' dangerouslySetInnerHTML={{ __html: paragraph }} />
									</Col>
								)}
							</Grid>
						</Col>
					</WrapperGrid>
				</TemplatePage>
			)}

			{modalFilterOpen && (
				<FinancialFilter
					turnOff={showModalConfirm.message === 'DESATIVAR'}
					setShowModal={setModalFilterOpen}
					onConfirm={showModalConfirm.onConfirm}
				/>
			)}
		</ComponentWrapper>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				financial: financeiro {
					title: titulo
					listTitle: titulo_lista
					paragraph: texto_lista_vazia
					button: botao
					cards: cartoes {
						title: titulo
						paragraph: paragrafo
						icon: icone
						bg: cor
					}
				}
			}
		}
	}
`

export default Financial
