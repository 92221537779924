import { Col as C, Grid as G } from '@cuidardigital/commons/components/Grid/grid'
import styled from 'styled-components'
import { Button as B, ButtonLink as BL, Span as S, SpanHighlight } from '../superSetCommons'

export const Col = styled(C)``
export const Grid = styled(G)``
export const Span = styled(S)``

export const PatientLinkWrapper = styled(SpanHighlight)`
	cursor: pointer;
	text-align: left;
	-webkit-text-decoration: underline;
	text-decoration: underline;
	-webkit-letter-spacing: 0;
	-moz-letter-spacing: 0;
	-ms-letter-spacing: 0;
	letter-spacing: 0;
	width: min-content;
`

interface IWrapper {
	height?: string | number
	mt?: string | number
	bg?: string
}
export const ButtonLink = styled(BL)`
	color: ${props => props.theme.colors.red_alert};
`

export const ComponentWrapper = styled(G)`
	background: ${({ bg, theme }) => (bg && theme.colors[bg]) || '#ffffff'} 0% 0% no-repeat
		padding-box;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
`

export const Divider = styled.hr`
	opacity: 0.2;
`

export const NewLink = styled.a`
	cursor: pointer;
	text-align: left;
	text-decoration: underline;
	font: Bold 14px/20px CentraleSans-Bold;
	letter-spacing: 0;
	color: ${props => props.theme.colors.secundary};

	@media (max-width: 1340px) {
		font: Bold 10px/20px CentraleSans-Bold;
	}

	@media (max-width: 768px) {
		font: Bold 8px/20px CentraleSans-Bold;
	}
`

export const Button = styled(B)`
	margin: 20px 0 0;
`

// closed
export const ButtonContainer = styled.div`
	padding-top: 7px;
	padding-bottom: 5px;
`
export const ClosedComponentWrapper = styled(G)`
	background: ${({ bg }: IWrapper) => bg || '#ffffff'} 0% 0% no-repeat padding-box;
	border-bottom: 1px solid #e6e6e6;
	opacity: 1;
	padding: 22px;
	grid-row-gap: 0;
`

export const EmailSpan = styled(Span)`
	display: block;
	white-space: inherit;
	width: 100%;
`

export const ClosedButton = styled.button`
	width: 150px;
	padding: 10px;
	margin: 8px 0;
	font-family: 'CentraleSans-Bold';
	border-radius: 3.5px;
	border: 1px solid ${props => props.theme.colors.secundary_border};
	color: ${props => props.theme.colors.font_secundary};
	background: ${props => props.theme.colors.secundary};
	&:hover {
		background: ${props => props.theme.colors.secundary_hover};
	}
`
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 15px;
	border-bottom: 0.5px solid #d9d9d9;
`
export const PatientName = styled.span`
	font-size: 18px;
	font-weight: bold;
	font-family: 'CentraleSans-Bold';
	color: #333;
`

export const PatientInfo = styled.span`
	color: #999999;
	font-size: 14px;
`

export const DateInfo = styled.span`
	font-size: 14px;
	font-weight: bold;
	font-family: 'CentraleSans-Bold';
	color: #333;
	font-size: 14px;
`
export const Icon = styled.img`
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin: ${props => props.m || '10px'};
	${props =>
		props.bg &&
		`
	background: ${props.theme.colors[props.bg]};
    border-radius: 8px;
    padding: 3px;`}
`

export const FinancialWrapper = styled(ComponentWrapper)`
	width: auto;
	height: 100%;
`
