import moment from 'moment'
import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { Col, FinancialWrapper, Icon, Span } from './styles'
interface IProps {
	icon: string
	title: string
	text: string
	info: string
	bg: string
	htmlId: string
}

const FinancialResume = ({ icon, title, text, info, bg, htmlId }: IProps) => {
	return (
		<FinancialWrapper id={htmlId} padding='0 10px' rowGap='0' rowMinMax='10vh'>
			<Col size='12' mobile='8' flex alingItems='center'>
				<Icon m='0' id='icon-digital-sign-close' src={icon} bg={bg} />
				<Span bold color='font_disabled' fontSize='16px/24px' wrap padding='0 2vw 0'>
					{title}
				</Span>
			</Col>
			<Col size='11' padding='10px 0'>
				<Span wrap fontSize='24px/24px'>
					{text}
				</Span>
			</Col>
			<Col size='11' padding='0 0 2vh 0'>
				<Span wrap fontSize='12px/14px' dangerouslySetInnerHTML={{ __html: info }} />
			</Col>
		</FinancialWrapper>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				dashboard {
					consultation: consulta
					patientInfo: dados_do_paciente
				}
			}
		}
	}
`

export default FinancialResume
