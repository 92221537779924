import { formatCurrency } from '@cuidardigital/commons/utils/coin'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Col, ComponentWrapper, ComponentWrapperMobile, Grid, Span } from '../styles'
import { ButtonRedMobile, Button, Arrow } from './styles'

interface IPropsCollapse {
	date: string
	active: boolean
	onCollapse: () => void
	onCancel: (args: any) => void
	onResend: (args: any) => void
	name: string
	birthDayFormated: string
	value: string
	status: string
	htmlId: string
}

const ENUM_STATUS_COLOR = {
	PENDENT: 'orange',
	APPROVED: 'green',
	CREATED: 'green'
}

const CollapseFinancial = ({
	active,
	onCollapse,
	name,
	birthDayFormated,
	status,
	value,
	htmlId
}: IPropsCollapse) => {
	const data = useStaticQuery(query)
	const { buttonCancel, buttonResend, title } = data.saudeDigital.metadata.financial.collapse

	return (
		<>
			<ComponentWrapperMobile onClick={onCollapse}>
				<Col size='10'>
					<Span bold fontSize='14px/20px' wrap display='block'>
						{name}
					</Span>
					<Span fontSize='14px/20px' color={ENUM_STATUS_COLOR[status]}>
						Pendente
					</Span>
				</Col>
				<Col
					size='2'
					alingItems='center'
					flex
					justifyContent='flex-end'
					className={active ? 'opened' : 'closed'}
				>
					<Arrow />
				</Col>
				<Col size='12'>
					<Grid className={active ? 'show' : 'hidden'}>
						<Col size='12'>
							<Span bold fontSize='12px/20px' display='block' color='gray60'>
								Data de nascimento
							</Span>
							<Span fontSize='14px/20px'>{birthDayFormated}</Span>
						</Col>
						<Col size='6'>
							<Span bold fontSize='12px/20px' display='block' color='gray60'>
								Valor da consulta
							</Span>
							<Span fontSize='14px/20px'>{formatCurrency(value)}</Span>
						</Col>
						<Col size='6'>
							<Span bold fontSize='12px/20px' display='block' color='gray60'>
								Data da consulta
							</Span>
							<Span fontSize='14px/20px'>{birthDayFormated}</Span>
						</Col>
						<Col size='12'>
							<Span color='red_alert' fontSize='14px/22px'>
								{title}
							</Span>
							<hr />
						</Col>
						<Col size='6'>
							<Span bold fontSize='12px/20px' wrap display='block' color='gray60'>
								Método de pagamento
							</Span>
							<Span fontSize='14px/20px'>Boleto bancário</Span>
						</Col>
						<Col size='6'>
							<Span bold fontSize='12px/20px' display='block' color='gray60'>
								Data de emissão
							</Span>
							<Span fontSize='14px/20px'>{birthDayFormated}</Span>
						</Col>
						<Col size='12' flex justifyContent='flex-end' xsmJustifyContent='flex-start'>
							<Button id='button-resend-payment' padding='0px 5px' fullWidth type='secundary-void'>
								<Span fontSize='14px/20px' color='white' bold color='blue'>
									{buttonResend}
								</Span>
							</Button>
						</Col>
						<Col size='12' flex justifyContent='flex-end' xsmJustifyContent='flex-start'>
							<Button type='alert' id='button-cancel-payment' p='0px 5px' fullWidth>
								<Span fontSize='14px/20px' color='white' bold>
									{buttonCancel}
								</Span>
							</Button>
						</Col>
					</Grid>
				</Col>
			</ComponentWrapperMobile>
			<ComponentWrapper onClick={onCollapse} notMobile>
				<Col id={htmlId} size='7' tablet='7' mobile='11'>
					<Grid>
						<Col size='4' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' wrap display='block' color='gray60'>
								Nome
							</Span>
							<Span wrap fontSize='14px/20px'>
								{name}
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' display='block' wrap color='gray60'>
								Data de nascimento
							</Span>
							<Span fontSize='14px/20px'>{birthDayFormated}</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' display='block' wrap color='gray60'>
								Status do Pagamento:
							</Span>
							<Span fontSize='14px/20px' color={ENUM_STATUS_COLOR[status]}>
								Pendente
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span wrap bold fontSize='12px/20px' display='block' color='gray60'>
								Valor da consulta
							</Span>
							<Span wrap fontSize='14px/20px'>
								{formatCurrency(value)}
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span wrap bold fontSize='12px/20px' display='block' color='gray60'>
								Data da consulta
							</Span>
							<Span wrap fontSize='14px/20px'>
								{birthDayFormated}
							</Span>
						</Col>
					</Grid>
				</Col>
				<Col
					size='4'
					hd='4'
					tablet='4'
					mobile='11'
					flex
					justifyContent='flex-end'
					xsmJustifyContent='flex-start'
				>
					<Grid>
						<Col size='6'>
							<Button id='button-resend-payment' padding='0px 10px' type='primary-void' fullWidth>
								<Span fontSize='14px/20px' color='primary' bold wrap>
									{buttonResend}
								</Span>
							</Button>
						</Col>
						<Col size='6'>
							<Button type='alert' id='button-cancel-payment' padding='0px 10px' fullWidth>
								<Span fontSize='14px/20px' color='white' bold wrap>
									{buttonCancel}
								</Span>
							</Button>
						</Col>
					</Grid>
				</Col>
				<Col
					size='1'
					tablet='1'
					mobile='1'
					alingItems='center'
					flex
					justifyContent='flex-end'
					className={active ? 'opened' : 'closed'}
				>
					<Arrow />
				</Col>
				<Col size='12'>
					<Grid className={active ? 'show' : 'hidden'}>
						<Col size='12'>
							<Span color='red_alert' fontSize='14px/22px'>
								{title}
							</Span>
							<hr />
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' wrap display='block' color='gray60'>
								Método de pagamento
							</Span>
							<Span wrap fontSize='14px/20px'>
								Boleto bancário
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' wrap display='block' color='gray60'>
								Data de emissão
							</Span>
							<Span wrap fontSize='14px/20px'>
								{birthDayFormated}
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Span bold fontSize='12px/20px' wrap display='block' color='gray60'>
								Data de pagamento
							</Span>
							<Span wrap fontSize='14px/20px'>
								{birthDayFormated}
							</Span>
						</Col>
						<Col size='2' hd='3' tablet='4' mobile='6'>
							<Button type='blue' isLink>
								<Span bold fontSize='12px/20px' wrap padding='0 10px 0 0'>
									Alterar forma de pagamento
								</Span>
								<Arrow color='#2760E3' toRight />
							</Button>
						</Col>
					</Grid>
				</Col>
			</ComponentWrapper>
		</>
	)
}

export default CollapseFinancial

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "fleury" }) {
			metadata {
				financial: financeiro {
					collapse {
						buttonCancel: botao_cancelar
						buttonResend: botao_reenviar
						title: titulo_expandido
					}
				}
			}
		}
	}
`
