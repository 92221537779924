import { IconArrow } from '@cuidardigital/commons/assets/icons'
import Modal from '@cuidardigital/commons/components/Modal'
import Select from '@cuidardigital/commons/components/Select'
import { formatDate } from '@cuidardigital/commons/utils/masks'
import React, { useCallback, useState } from 'react'
import {
	Button,
	ButtonCancel,
	ButtonLink,
	Col,
	ContainerFixed,
	Grid,
	Icon,
	InputElement,
	PrimaryButton,
	Span
} from './styles'

const periodGrouped = [
	['7 dias', '15 dias'],
	['30 dias', 'Este mês']
]

const FinancialFilter = ({ setShowModal, children, onConfirm, turnOff }) => {
	const [filterValues, setFilterValues] = useState({
		startDate: '',
		endDate: '',
		name: '',
		birthDay: '',
		uf: '',
		status: {
			pendent: false,
			finished: false,
			waiting: false
		},
		period: '',
		speciality: ''
	})

	const [showSpeciality, setShowSpeciality] = useState(false)
	const [showDate, setShowDate] = useState(false)

	const handleStatus = useCallback(
		status => () => {
			setFilterValues(prev => ({
				...prev,
				status: { ...prev.status, [status]: !prev.status[status] }
			}))
		},
		[]
	)
	const closeModal = useCallback(() => setShowModal(false), [])
	const handleShowSpeciality = useCallback(() => {
		setShowSpeciality(prev => !prev)
		setFilterValues(prev => ({ ...prev, speciality: '' }))
	}, [])
	const handleShowDate = useCallback(() => setShowDate(prev => !prev), [])
	const handleFilterDate = useCallback(
		field => e => {
			const { value } = e.target
			setFilterValues(prev => ({ ...prev, [field]: formatDate(value) }))
		},
		[]
	)
	const handleName = useCallback(e => {
		const { value } = e.target
		setFilterValues(prev => ({ ...prev, name: value }))
	}, [])
	const handlePeriod = useCallback(
		period => () => {
			setShowDate(false)
			setFilterValues(prev => ({
				...prev,
				period: prev.period !== period && period,
				startDate: '',
				endDate: ''
			}))
		},
		[]
	)
	const handleSpeciality = useCallback(
		speciality => () => {
			setFilterValues(prev => ({
				...prev,
				speciality: prev.speciality !== speciality && speciality
			}))
			setShowSpeciality(false)
		},
		[]
	)
	const handleUf = useCallback(uf => {
		setFilterValues(prev => ({ ...prev, uf }))
	}, [])

	const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') setShowDate(false)
	}, [])

	return (
		<Modal maxWidth='70vw' padTop='20vh' zIndex='11' textAlign='left' onClose={closeModal}>
			<Grid>
				<Col size='12'>
					<Span fontSize='24px/24px' color='red_alert'>
						Filtrar consultas
					</Span>
				</Col>
				<Col size='12' flex>
					<Icon src='https://imgix.cosmicjs.com/86196860-14aa-11eb-8fef-9f435dae3a70-user.svg' />
					<Span fontSize='16px/20px'>Por paciente</Span>
				</Col>
				<Col size='12' columnGap='10px'>
					<Grid overflow='visible'>
						<Col size='4' mobile='12'>
							<Span fontSize='14px/20px' bold display='block' padding='0px 0px 5px'>
								Nome
							</Span>
							<InputElement withBorder placeholder='Digite seu nome' onChange={handleName} />
						</Col>
						<Col size='3' mobile='12'>
							<Span fontSize='14px/20px' bold display='block' padding='0px 0px 5px'>
								Data de nascimento
							</Span>
							<InputElement
								withBorder
								placeholder='00/00/0000'
								onChange={handleFilterDate('birthDay')}
								value={filterValues.birthDay}
							/>
						</Col>
						<Col size='3' mobile='12' overflow='hidden'>
							<Span fontSize='14px/20px' bold display='block' padding='0px 0px 5px'>
								Estado
							</Span>
							<Select
								id='select-uf'
								options={[{ value: 'sp', label: 'SP' }]}
								placeholder='Selecione'
								value={filterValues.uf}
								onChange={handleUf}
								maxMenuHeight={150}
								inputId='select-uf'
								bg='#CCCCCC'
							/>
						</Col>
					</Grid>
				</Col>
				<Col size='12' flex padding='10px 0 0 0'>
					<Icon src='https://imgix.cosmicjs.com/cd6dc0d0-14aa-11eb-8fef-9f435dae3a70-cifrao-gray.svg' />
					<Span fontSize='16px/20px'>Por status de pagamento</Span>
				</Col>
				<Col size='12'>
					<Grid rowGap='1vh' columnGap='2px'>
						<Col size='3' mobile='12' padding='0'>
							<ButtonLink
								display='flex'
								bg={filterValues.status.finished && 'gray'}
								onClick={handleStatus('finished')}
								alignItems
							>
								<Icon
									p='3px'
									radius='8px'
									bg='green'
									src='https://imgix.cosmicjs.com/9e6905a0-0ee3-11eb-881e-79bc840bf7c4-cifrao.svg'
								/>
								<Span
									fontSize='14px/20px'
									bold
									color={filterValues.status.finished ? 'white' : 'gray'}
									padding='0px 5px 0 10px'
								>
									Saldo liberado
								</Span>
							</ButtonLink>
						</Col>
						<Col size='3' mobile='12'>
							<ButtonLink
								display='flex'
								bg={filterValues.status.waiting && 'gray'}
								onClick={handleStatus('waiting')}
								alignItems
							>
								<Icon
									p='3px'
									radius='8px'
									bg='yellow'
									src='https://imgix.cosmicjs.com/9e6905a0-0ee3-11eb-881e-79bc840bf7c4-cifrao.svg'
								/>
								<Span
									fontSize='14px/20px'
									bold
									color={filterValues.status.waiting ? 'white' : 'gray'}
									padding='0px 5px 0 10px'
								>
									Saldo a liberar
								</Span>
							</ButtonLink>
						</Col>
						<Col size='3' mobile='12'>
							<ButtonLink
								display='flex'
								bg={filterValues.status.pendent && 'gray'}
								onClick={handleStatus('pendent')}
								alignItems
							>
								<Icon
									p='3px'
									radius='8px'
									bg='orange'
									src='https://imgix.cosmicjs.com/9e6905a0-0ee3-11eb-881e-79bc840bf7c4-cifrao.svg'
								/>
								<Span
									fontSize='14px/20px'
									bold
									color={filterValues.status.pendent ? 'white' : 'gray'}
									padding='0px 5px 0 10px'
								>
									Pendente
								</Span>
							</ButtonLink>
						</Col>
					</Grid>
				</Col>
				<Col size='4' mobile='12'>
					<ButtonLink
						display='flex'
						onClick={handleShowSpeciality}
						className={showSpeciality ? 'opened' : 'closed'}
					>
						<Icon src='https://imgix.cosmicjs.com/28d2d140-14ab-11eb-8fef-9f435dae3a70-especialidade.svg' />
						<Span fontSize='16px/20px' padding='0 0 0 5px'>
							{filterValues.speciality || 'Por especialidade'}
						</Span>
						<IconArrow />
					</ButtonLink>
					{showSpeciality && (
						<ContainerFixed>
							<Col size='12'>
								<ButtonLink
									onClick={handleSpeciality('Clínica médica')}
									borderBottom='font_disabled'
									radius='0px'
								>
									<Span fontSize='16px/20px' padding='0 0 0 5px' color='font_dark'>
										Clínica médica
									</Span>
								</ButtonLink>
							</Col>
							<Col size='12'>
								<ButtonLink
									onClick={handleSpeciality('Ginecologia')}
									borderBottom='font_disabled'
									radius='0px'
								>
									<Span fontSize='16px/20px' padding='0 0 0 5px' color='font_dark'>
										Ginecologia
									</Span>
								</ButtonLink>
							</Col>
							<Col size='12'>
								<ButtonLink
									onClick={handleSpeciality('Ortopedia')}
									borderBottom='font_disabled'
									radius='0px'
								>
									<Span fontSize='16px/20px' padding='0 0 0 5px' color='font_dark'>
										Ortopedia
									</Span>
								</ButtonLink>
							</Col>
						</ContainerFixed>
					)}
				</Col>
				<Col size='5' mobile='12'>
					<ButtonLink
						display='flex'
						onClick={handleShowDate}
						className={showDate ? 'opened' : 'closed'}
					>
						<Icon src='https://imgix.cosmicjs.com/28d3e2b0-14ab-11eb-8fef-9f435dae3a70-calendar.svg' />
						<Span fontSize='16px/20px' padding='0 0 0 5px'>
							{(filterValues.startDate &&
								filterValues.endDate &&
								`${filterValues.startDate} a ${filterValues.endDate}`) ||
								filterValues.period ||
								'Por data'}
						</Span>
						<IconArrow />
					</ButtonLink>
					{showDate && (
						<ContainerFixed>
							{periodGrouped.map((periods, index) => (
								<Col key={`period-grouped-${index}`} size='12' flex columnGap='10px'>
									{periods.map(period => (
										<ButtonLink
											key={`period-${period}`}
											bg={filterValues.period === period ? 'darkGray' : 'shine_line'}
											radius='21px'
											width='auto'
											onClick={handlePeriod(period)}
										>
											<Icon src='https://imgix.cosmicjs.com/28d3e2b0-14ab-11eb-8fef-9f435dae3a70-calendar.svg' />
											<Span
												fontSize='14px/20px'
												padding='0 5px 0 10px'
												color={filterValues.period === period && 'white'}
											>
												{period}
											</Span>
										</ButtonLink>
									))}
								</Col>
							))}
							<Col size='12' flex columnGap='10px'>
								<div>
									<Span fontSize='14px/20px' bold display='block' padding='0px 0px 5px'>
										Data inicial
									</Span>
									<InputElement
										withBorder
										placeholder='00/00/0000'
										onChange={handleFilterDate('startDate')}
										value={filterValues.startDate}
										onKeyPress={handleKeyPress}
									/>
								</div>
								<div>
									<Span fontSize='14px/20px' bold display='block' padding='0px 0px 5px'>
										Data final
									</Span>
									<InputElement
										withBorder
										placeholder='00/00/0000'
										onChange={handleFilterDate('endDate')}
										value={filterValues.endDate}
										onKeyPress={handleKeyPress}
									/>
								</div>
							</Col>
						</ContainerFixed>
					)}
				</Col>
				<Col size='12'>
					<Grid>
						<Col size='2' tablet='4' mobile='6'>
							<PrimaryButton id='button-filter' onClick={onConfirm}>
								Filtrar
							</PrimaryButton>
						</Col>
						<Col size='2' tablet='4' mobile='6'>
							<ButtonCancel id='button-cancel' onClick={closeModal}>
								Cancelar
							</ButtonCancel>
						</Col>
					</Grid>
				</Col>
			</Grid>
		</Modal>
	)
}

export default FinancialFilter
