import { IconArrow } from '@cuidardigital/commons/assets/icons'
import { Col, Grid } from '@cuidardigital/commons/components/Grid/grid'
import styled from 'styled-components'
import {
	Span,
	SpanHighlight,
	SubmitButtonRed,
	ButtonStyled as Button,
	PrimaryButton
} from '../../superSetCommons'

export { Col, Grid, Button, Span, SpanHighlight, PrimaryButton }

export const ButtonRedMobile = styled(SubmitButtonRed)`
	margin: 0;
	margin-top: 10px;
	border-radius: 4px;
`

export const Arrow = styled(IconArrow)`
	${({ toRight }) => toRight && 'transform: rotate(-90deg);'}
`
