import React from 'react'

export default function(props) {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
			<defs>
				<style>{'.a-arrow-back{fill:none;}.b-arrow-back{fill:#333;}'}</style>
			</defs>
			<path className='a-arrow-back' d='M0,0H32V32H0Z' />
			<path
				className='b-arrow-back'
				d='M25.331,13.332H9.106L16.558,5.88,14.665,4,4,14.665,14.665,25.331l1.88-1.88L9.106,16H25.331Z'
				transform='translate(1.335 1.335)'
			/>
		</svg>
	)
}
