import { Col, Grid } from '@cuidardigital/commons/components/Grid/grid'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
	BasicButton as B,
	ButtonLink as BL,
	ButtonReturn,
	InputElement as IE,
	PrimaryButton,
	Span as S,
	FileButton,
	ButtonStyled,
	ButtonCheckable
} from '../superSetCommons'
import { IconArrow } from '@cuidardigital/commons/assets/icons'

interface IWrapper {
	height?: string | number
	mt?: string | number
}

export const ButtonLink = styled(BL)`
	text-align: left;
	${({ theme, bg }) =>
		bg &&
		`&:hover {
			background: ${theme.colors[bg]};
		}`}
`
export { Col, Grid, PrimaryButton, FileButton, ButtonStyled, ButtonCheckable }

export const ButtonCancel = styled(ButtonReturn)`
	margin: 0;
	width: 100%;
	width: ${({ width }) => width};
`
export const Button = styled(B)`
	color: ${props => props.theme.colors.font};
	// background: ${props => props.theme.colors.font_dark};
	border-radius: 21.5px;
	&:hover {
		opacity: 0.8;
	}

	svg {
		vertical-align: bottom;
	${({ theme, icon }) =>
		icon && `background: ${theme.colors.white}; border-radius: 10px; padding: 2px;`}
	}
`
export const Span = styled(S)`
	margin: auto 0;
`

export const SpanContainer = styled.div`
	padding: 0 10px;
`

export const ComponentWrapper = styled.div`
	min-height: 100vh;
	background: #f7f7f7 0% 0% no-repeat padding-box;
	width: 100%;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	opacity: 1;
	margin-top: ${({ mt }: IWrapper) => mt + 'px'};
`

export const WrapperGrid = styled(Grid)`
	width: 100%;
	box-shadow: 0px 0px 10px #00000026;
	border-radius: 8px;
	padding: 10px 20px 20px;

	.left {
		padding-top: 38px;

		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 0px 10px #00000026;
	}
	.right {
		padding: 0 40px 10px;

		@media (max-width: 480px) {
			padding: 0 10px 5px;
		}
	}
`

export const NoContentWrapper = styled(Grid)`
	padding: 0 10px 5px;
	margin: 38px 0 0;
	@media (min-width: 480px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	@media (max-width: 480px) {
		margin: 18px 0 0;
	}
`

export const ColAssistant = styled(Col)`
	h1{
		margin 0;
		padding-left: 2%;
	}

	@media (min-width: 480px) {
    width: 37%;
		}

	// button{
	// 	width: 40%;

	// 	@media (max-width: 1700px) {
	// 		width: 45%;
	// 	}
	// 	@media (max-width: 1440px) {
	// 		width: 60%;
	// 	}
	// 	@media (max-width: 1039px) {
	// 		width: 80%;
	// 	}
	// 	@media (max-width: 777px) {
	// 		width: 90%;
	// 	}
	// }
`
export const ColBreadCrumb = styled(ColAssistant)`
	#butto-arrow-back {
		margin: auto 0;
	}

	@media (min-width: 480px) {
		width: 80%;
	}
`

export const LinkPage = styled(Link)`
	padding: 0 0 0 10px;
	text-decoration: underline;
	color: ${props => props.theme.colors.font_dark};

	// @media (max-width: 950px) {
	// 	margin: 2% 1%;
	// }
`

export const Icon = styled.img`
					vertical-align: bottom;
					width: 22px;
					${({ theme, bg }) => bg && `background: ${theme.colors[bg]};`}
					${({ radius }) => radius && `border-radius: ${radius};`}
					${({ p }) => p && `padding: ${p};`}
				`
export const InputElement = styled(IE)`
	width: 100%;
	background: ${props => props.theme.colors.gray10};
`

export const ContainerFixed = styled(Grid)`
	position: fixed;
	background: ${props => props.theme.colors.white};
	box-shadow: 0px 20px 50px -24px ${props => props.theme.colors.black};
	border-radius: 4px;
	padding: 10px;
`

export const GridResume = styled(Grid)`
	@media (max-width: 480px) {
		display: grid;
		grid-gap: calc(20px / 2);
		grid-template-columns: repeat(5, 40vw);
		// grid-template-rows: minmax(150px, 1fr);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
		// padding-bottom: calc(0.75 * 20px);
		// margin-bottom: calc(-0.25 * 20px);
		width: 100vw;
		padding: 0px 10px;
	}
`

export const ColContainer = styled(Col)`
	@media (max-width: 480px) {
		width: 100vw;
		margin-left: -20px;
	}
`

export const Checkbox = styled.input``
export const ColCard = styled(Col)`
	background: ${({ active, theme }) => (active ? theme.colors.blue : theme.colors.gray10)};
	box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
`

export const Logo = styled.img`
	display: block;
	width: 100%;
	max-width: 420px;
	margin: 0 0 auto;
`
export const Arrow = styled(IconArrow)`
	border-radius: 10px;
	background: ${({ active, theme }) => (active ? theme.colors.gray10 : theme.colors.transparent)};
	transform: rotate(-90deg);
`
export const ColDoc = styled(Col)`
	.center {
		text-align: center;
	}
`
export const SpanRoot = styled(S)``
